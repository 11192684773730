import { HyperLink } from "../../classes/HyperLink";
import { BlockImageCopyQuery } from "../../fragments/NodeRichTextFields";
import ImageCopyBlock from "./Blocks/ImageCopy";
import * as React from "react";
import { Img } from "wmk-image";
import ReactPlayer from "react-player";

/*
  button?: HyperLink;
  buttonLabel?: string;
  copy: string;
  headline?: string;
  image: Img | React.ReactElement;
  imagePosition: "First" | "Last";
  space?: boolean;
  id?: string;
  */

export const blockHash = {
  ContentfulYoutubeVideo: ({ url }: { url: string }) => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ReactPlayer url={url} controls={true} />
      </div>
    );
  },
  ContentfulBlockImageCopy: (props: BlockImageCopyQuery) => {
    return (
      <ImageCopyBlock
        button={new HyperLink(props.button)}
        buttonLabel={props.buttonLabel}
        copy={props.copy.text}
        headline={props.headline}
        image={new Img(props.image)}
        imagePosition={props.imagePosition}
        id={props.imageCopyId}
        space={props.space}
      />
    );
  }
};
