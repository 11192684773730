import * as React from "react";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";
import { RichText, RichTextReact } from "wmk-rich-text";
import { options } from "../RichText/richTextOptions";
import { colors } from "../ui/variables";

const BlogBodyWrap = styled(Col)`
  padding: 0 3rem;
  max-width: 780px !important;
  width: 100%;
  margin: 2rem auto !important;
  & > div:first-child {
    font-size: 175%;
    line-height: 1.3;
    color: ${colors.blue.hex};
    text-transform: uppercase;
    a {
      color: ${colors.darkBlue.hex};
      border: none;
      text-decoration: underline !important;
      &:hover {
        color: ${colors.text.hex};
        u {
          text-decoration: none;
        }
      }
    }
  }
  div:not(:first-child) {
    margin: 2rem 0;
    line-height: 1.75;
    font-size: 1.425rem;
  }
  h2,
  h3,
  h6,
  blockquote {
    margin: 2rem 0;
    color: ${colors.blue.hex};
    text-transform: uppercase;
  }
  h5,
  h6,
  blockquote {
    font-weight: bold;
    text-transform: uppercase;
  }
  h6,
  blockquote {
    font-size: 1.5rem;
  }
  b,
  strong {
    font-weight: 600;
  }
  a {
    color: ${colors.darkBlue.hex};
    transition: all 0.3s ease;
    border-bottom: 1px solid ${colors.text.hex};
    text-decoration: none;
    &.cta_button {
      border: none;
    }
    &:hover {
      text-decoration: none;
      transition: all 0.3s ease;
      color: ${colors.text.hex};
      border: 0px solid ${colors.lightBlue.hex};
    }
    u {
      text-decoration: none;
    }
  }
  .embedded-image-wrap {
    text-align: center;
    padding: 1rem 0 2rem 0;
  }
  .embedded-youtube-wrap {
    text-align: center;
    padding: 1rem 0 2rem 0;
  }
  img {
    max-width: 100%;
  }
  ol,
  ul {
    padding: 0 0 0 1.5rem;
    line-height: 1.75;
    font-size: 1.15rem;
  }
  li::marker {
    color: ${colors.blue.hex};
  }
  li > p {
    margin-bottom: 0;
  }
  li a {
    font-size: 1.15rem;
  }
  .gatsby-image-wrapper {
    display: inline-flex;
  }
`;

const BlogBody = ({ content }: { content: RichText }) => {
  return (
    <Container>
      <Row>
        <BlogBodyWrap>
          <RichTextReact content={content} options={options} />
        </BlogBodyWrap>
      </Row>
    </Container>
  );
};

export default BlogBody;
