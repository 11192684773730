import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import PepperButton from "../../ui/MainButton";
import { Typography } from "../../ui/Typography";
import { colors } from "../../ui/variables";
import { useRef, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Background, Parallax } from "react-parallax";
import { Slide, Fade } from "react-awesome-reveal";
import { HyperLink } from "../../../classes/HyperLink";
import { Img, WmkImage } from "wmk-image";

const CopyWrap = styled(Col)`
  order: ${({ order }) => order};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ space }) => (space ? `calc(7vh + ${space}px)` : `7vh`)} 0 4vh 0;
  //margin-top: ${({ space }) => (space ? `-${space}px` : 0)};
  @media screen and (min-width: 740px) {
    padding: ${({ space }) => (space ? `calc(12vh + ${space}px)` : `12vh`)} 0
      12vh 0;
  }
`;

const ImageWrap = styled(Col)`
  order: ${({ order }) => order};
  padding: 0;
`;

const DynImageWrap = styled.div<{
  size: { height?: number; width?: number };
  parallax?: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ size }) => (size.height ? size.height : 0)}px;
  .gatsby-image-wrapper,
  .react-parallax {
    height: ${({ size, parallax }) => {
      const height = size.height ? size.height : 0;
      return parallax ? height * 1.2 : height;
    }}px;
    ${({ size, parallax }) =>
      parallax ? `width: ${size.width ? size.width : 0}px;` : ``}
  }
`;

const ProjectManagementStyling = styled(ReactMarkdown)`
  ol {
    width: 100%;
    list-style-type: none;
    counter-reset: project-management-counter;
    padding-left: 0;
  }
  li {
    counter-increment: project-management-counter;
    overflow-wrap: break-word;
    hyphens: auto;
  }
  li strong {
    color: ${colors.blue.hex};
  }
  li::before {
    content: counter(project-management-counter);
    color: white;
    display: inline-block;
    width: 2.4rem;
    line-height: 2rem;
    background: ${colors.blue.hex};
    text-align: center;
    position: absolute;
  }
  li:nth-child(odd) {
    width: calc(50% + 4px);
    text-align: left;
    position: relative;
    border-left: 4px solid ${colors.blue.hex};
    margin-left: 50%;
    padding-left: 4rem;
  }
  ol li:nth-child(odd):before {
    border-radius: 0 30px 30px 0;
    top: 0;
    left: 0;
  }
  li:nth-child(even) {
    width: calc(50% + 4px);
    text-align: right;
    position: relative;
    direction: ltr;
    border-right: 4px solid ${colors.blue.hex};
    padding-right: 4rem;
  }
  ol li:nth-child(even):before {
    border-radius: 30px 0 0 30px;
    top: 0;
    right: 0;
  }
  li:last-child {
    border: none;
    border-right: 4px solid none;
  }
  @media screen and (max-width: 740px) {
    li:nth-child(odd) {
      width: 100%;
      text-align: center;
      border-left: none;
      margin-left: 0;
      padding: 4rem 0 6rem 0;
    }
    ol li:nth-child(odd):before {
      top: 0;
      left: calc(50% + 4px);
    }
    li:nth-child(even) {
      width: 100%;
      text-align: center;
      border-right: none;
      padding: 4rem 0 6rem 0;
    }
    ol li:nth-child(even):before {
      top: 0;
      right: 50%;
    }
    li::after {
      content: "";
      height: 8rem;
      width: 5px;
      background: ${colors.blue.hex};
      position: absolute;
      top: -5rem;
      left: 50%;
    }
    li:first-child::after {
      height: 3rem;
      top: 0;
    }
  }
`;

const ImageCopyBlock = ({
  button,
  buttonLabel,
  copy,
  headline,
  image,
  imagePosition,
  space,
  id
}: {
  button?: HyperLink;
  buttonLabel?: string;
  copy: string;
  headline?: string;
  image: Img | React.ReactElement;
  imagePosition: "First" | "Last";
  space?: number;
  id?: string;
}) => {
  const [contentSize, setContentSize] = useState({ width: 0, height: 0 });
  const imgOrder = imagePosition === "First" ? 1 : 2;
  const copyOrder = imagePosition === "First" ? 2 : 1;
  const to = button.to;
  const text = buttonLabel ? buttonLabel : button.text;
  const target = button.target;

  const contentRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const current = contentRef.current;
    const handleResize = () => {
      const rect = current.getBoundingClientRect();
      setContentSize({
        height: rect.height,
        width: rect.width
      });
    };
    window.addEventListener("resize", handleResize);
    const timeout = setTimeout(handleResize, 300);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeout);
    };
  }, [setContentSize]);
  return (
    <Container fluid style={{ padding: 0, marginTop: space ? -space : 0 }}>
      <Row style={{ margin: 0 }}>
        <ImageWrap
          order={imgOrder}
          sm={12}
          md={6}
          style={{
            position: "relative",
            overflow: "hidden",
            objectFit: "cover"
          }}>
          <DynImageWrap size={contentSize} parallax={image ? true : undefined}>
            {image instanceof Img ? (
              <Parallax strength={100}>
                <Background>
                  <WmkImage image={image} />
                </Background>
              </Parallax>
            ) : (
              image
            )}
          </DynImageWrap>
        </ImageWrap>
        <CopyWrap
          order={copyOrder}
          sm={12}
          md={6}
          ref={contentRef}
          space={space}>
          <div style={{ width: "80%" }}>
            <Row className="flex-column">
              <Col>
                <Slide
                  cascade
                  duration={600}
                  triggerOnce
                  direction={copyOrder === 1 ? "left" : "right"}
                  damping={0.5}>
                  <Typography.H2 style={{ color: colors.blue.hex }}>
                    {headline}
                  </Typography.H2>
                  <Typography.P>
                    {id === "Project Management Timeline" ? (
                      <ProjectManagementStyling>
                        {copy}
                      </ProjectManagementStyling>
                    ) : (
                      <ReactMarkdown>{copy}</ReactMarkdown>
                    )}
                  </Typography.P>
                  <br />
                </Slide>
                <Fade triggerOnce>
                  {to ? (
                    <PepperButton to={to} target={target}>
                      {text}
                    </PepperButton>
                  ) : null}
                </Fade>
              </Col>
            </Row>
          </div>
        </CopyWrap>
      </Row>
    </Container>
  );
};

export default ImageCopyBlock;
