import { graphql } from "gatsby";
import * as React from "react";
import { Container } from "react-bootstrap";
import GradientAngleHeader from "../components/layout/GradientAngleHeader";
import { useState } from "react";
import Layout from "../components/layout/MainLayout";
import { Blog, Blogs } from "../classes/Blog";
import { BlogQuery } from "../fragments/NodeBlogFields";
import BlogSingle from "../components/Blog/BlogSingle";
import { MetaDataQuery } from "../fragments/NodeSiteMetaFields";
import { WmkSeo } from "wmk-seo";

interface BlogNodeQuery {
  data: {
    all: {
      edges: { node: BlogQuery }[];
    };
    site: MetaDataQuery;
    blog: BlogQuery;
  };
}

const BlogNode = ({ data }: BlogNodeQuery) => {
  const [innerHeight, setInnerHeight] = useState();
  const meta = data.site.meta;
  const all = new Blogs(data.all.edges);
  const blog = new Blog(data.blog);
  const slug = blog.slug;
  return (
    <Layout>
      <WmkSeo.Meta
        title={blog.title}
        path="/blog/"
        slug={slug}
        siteTitle={meta?.title}
        baseUrl={meta?.baseUrl}
        description={blog.description ? blog.description : blog.title}
        twitterHandle={null}
        ogImageUrl={null}
        twitterImageUrl={null}
      />
      <GradientAngleHeader
        image={blog.image}
        height={innerHeight}
        setter={setInnerHeight}
        white={true}
        blog
      />
      <Container fluid style={{ padding: 0 }}>
        <BlogSingle blog={blog} />
      </Container>
    </Layout>
  );
};

export default BlogNode;

export const query = graphql`
  query($slug: String!) {
    all: allContentfulBlog {
      edges {
        node {
          ...NodeBlogFields
        }
      }
    }
    site {
      ...NodeSiteMetaFields
    }
    blog: contentfulBlog(slug: { eq: $slug }) {
      ...NodeBlogFields
    }
  }
`;
