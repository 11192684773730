import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { WmkLink } from "wmk-link";
import { EmbeddedBlock, getRichTextOptions, RichTextNode } from "wmk-rich-text";
import { blockHash } from "./BlockHash";
import * as React from "react";
import { Machine } from "../../classes/Machine";

export const options = getRichTextOptions({
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node: RichTextNode) => {
      const entry = new EmbeddedBlock(node, blockHash);
      return entry.render();
    },
    [INLINES.ENTRY_HYPERLINK]: (
      node: RichTextNode,
      children: React.ReactNode
    ) => {
      // console.log("node", node);
      const type = node?.reference?.__typename;
      const ErrorComp = () => (
        <>{console.log("no handler for entry link of " + type)}</>
      );
      if (type === "ContentfulBlog") {
        return (
          <WmkLink
            to={`/blog/${
              node?.reference?.data
                ? node?.reference?.data?.slug
                : node?.reference?.slug
            }`}
            target={"_blank"}>
            {children}
          </WmkLink>
        );
      }
      if (type === "ContentfulPage") {
        return (
          <WmkLink
            to={`/${
              node?.reference?.data
                ? node?.reference?.data?.slug
                : node?.reference?.slug
            }`}>
            {children}
          </WmkLink>
        );
      }
      if (type === "ContentfulMachines") {
        const machine = node?.reference?.data
          ? new Machine(node?.reference?.data)
          : "";
        return (
          <WmkLink to={`${machine ? machine?.link?.to : "/"}`}>
            {children}
          </WmkLink>
        );
      }
      return (
        <>
          {children}
          <ErrorComp />
        </>
      );
    }
  }
  //renderText: (text) => text.replace("!", "?")
});
