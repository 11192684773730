import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Blog } from "../../classes/Blog";
import { Typography } from "../ui/Typography";
import { colors } from "../ui/variables";
import BlogBody from "./BlogBody";
import BlogTags from "./BlogTags";

const BlogWrap = styled.div`
  padding-top: calc(10vh + 2rem);
  .title {
    text-transform: uppercase;
    text-align: center;
    color: ${colors.blue.hex};
  }
  .topics,
  .author {
    font-size: 0.9rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    & > .row {
      align-items: center;
    }
    .col-auto {
      padding: 0 0 0 2rem;
    }
  }
  .topics {
    text-align: right;
  }
  .col {
    justify-content: space-around;
  }
  .title {
    @media screen and (min-width: 400px) {
      font-size: 30px;
    }
    @media screen and (min-width: 768px) {
      font-size: 36px;
    }
    @media screen and (min-width: 900px) {
      font-size: 40px;
    }
    @media screen and (min-width: 1200px) {
      font-size: 54px;
    }
  }
`;

const BlogSingle = ({ blog }: { blog: Blog }) => {
  return (
    <BlogWrap>
      <Container>
        <Row className="flex-column blog-header">
          <Col>
            <Typography.H1 className="title">{blog.title}</Typography.H1>
          </Col>
          <Row>
            <Col>
              <BlogTags tags={blog.tags} singleBlog/>
            </Col>
          </Row>
        </Row>
        <BlogBody content={blog.content} />
      </Container>
    </BlogWrap>
  );
};

export default BlogSingle;
